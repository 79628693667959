    
/* Helper classes to help standardize field sizes mostly and prototype quickly 
    Please don't edit this, it's guaranteed to break stuff, if you need a custom size remove this class and use another selector
*/   
      
.sizeWee{
    width:25px;   
}
.sizeXXS{
    width:50px;   
}
.sizeXS{
    width:75px;   
}
.sizeS{
    width:100px;   
}
.sizeM{
    width:150px;
}
.sizeL{
    width:250px;
}
.sizeXL{
    width:400px;
}
.sizeXXL{
    width:500px;
}
.sizeFull{
    width:100%;
}

.pullLeft{
 
    display:inline-block;
    float:left;   
    
}.pullRight{
 
    display:inline-block;
    float:right;   
    
}
#clientForm select.sizeL {
    width: 250px;
}

.form-warning {
    
    font-weight: bold;
   color : white;    
    background : #ff004d;
    display : inline-block;
    padding:1em;
}