    
.page-title{    
    color: #FF6600;
    font-size: 15px;
}

.breadcrumbs {
    
    margin-bottom : 1em;    
    margin-left:1em;
}

.fc-block {
    
    border: 1px solid #CCCCCC;
    padding : 1em;
     margin-bottom : 2em;
}

.fc-block-clientPage {
    
    border: 1px solid #CCCCCC;
    padding : 20px 35px 20px 35px;
    margin-bottom : 1em;
}